import AppConstants from "../../constants";
import networkHandler from "../../network/networkHandler";
import networkHandlerReporting from "../../network/networkHandlerReporting";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { EApiMiddlewareMethods } from "../../network/networkHandler.types";
import { UserLoginState } from "./loginSlice.types";
import { checkRequiredUser } from "../../mocks/users/response.transforms";
import { matchAllElements } from "../../utils/helpers.utils";
import { emptySessionStorage, setSessionStorage } from "../LoginUtils";

export const userLogin = createAsyncThunk("UserLogin/Login", async ({ userdata }: { userdata: any }, { rejectWithValue }) => {
  try {
    const request = {
      url: "v2/authenticate/verify-otp",
      method: EApiMiddlewareMethods.POST,
      data: userdata,
    } as any;
    const { data } = await networkHandler(request, true, false, false, true);
    let userResponse = data;
    try {
      const countryRequest = {
        url: "fulfilment/country/url",
        method: EApiMiddlewareMethods.GET,
      } as any;
      countryRequest.headers = {
        countryISOCode: userResponse.countryCode,
        Authorization: `Bearer ${userResponse.jwtToken}`,
      };
      const { data: countryData } = await networkHandlerReporting(countryRequest);
      let countryUrl = countryData?.url?.wings ? countryData.url.wings : countryData?.countryUrl ? countryData.countryUrl : "";
      sessionStorage.setItem(AppConstants.COUNTRY_URL, countryUrl);
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data);
  }
});

export const newUserLogin = createAsyncThunk("UserLogin/newUserLogin", async ({ userdata }: { userdata: any }, { rejectWithValue }) => {
  try {
    const request = {
      url: "v2/authenticate",
      method: EApiMiddlewareMethods.POST,
      data: userdata,
    } as any;
    const { data } = await networkHandler(request, true, false, false, true);
    if (data?.jwtToken) {
      let userResponse = data;
      try {
        const countryRequest = { url: "fulfilment/country/url", method: EApiMiddlewareMethods.GET } as any;
        countryRequest.headers = { countryISOCode: userResponse.countryCode, Authorization: `Bearer ${userResponse.jwtToken}` };
        const { data: countryData } = await networkHandlerReporting(countryRequest);
        let countryUrl = countryData?.url?.wings ? countryData.url.wings : countryData?.countryUrl ? countryData.countryUrl : "";
        sessionStorage.setItem(AppConstants.COUNTRY_URL, countryUrl);
      } catch (error: any) {
        return rejectWithValue(error?.response?.data);
      }
    }
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const userLogout = createAsyncThunk("UserLogin/Logout", () => {
  return false;
});

export const resetUserResetPwd = createAsyncThunk("UserLogin/ResetUserResetPassword", () => {
  return false;
});

export const resetUserError = createAsyncThunk("UserLogin/ResetUserError", () => {
  return "";
});

export const userResetPwd = createAsyncThunk("UserLogin/ResetPassword", async ({ userdata }: { userdata: any }, { rejectWithValue }) => {
  try {
    const request = {
      url: "user/expiry/resetPassword",
      method: EApiMiddlewareMethods.POST,
      data: userdata,
    } as any;
    const { data } = await networkHandler(request, true);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

const initialState: UserLoginState = {
  isLoggedIn: sessionStorage.getItem("token") ? true : false,
  loading: false,
  errorCode: "",
  countryCode: "",
  error: "",
  username: "",
  resetPwdSuccess: false,
  resetPwdMsg: "",
  isAdmin: sessionStorage.getItem("role") ? checkRequiredUser(JSON.parse(sessionStorage.getItem("role") || "[]"), AppConstants.USERS_CONSTANTS.ADMIN_ROLES) : false,
  isCollectionPointManager: sessionStorage.getItem("role") ? checkRequiredUser(JSON.parse(sessionStorage.getItem("role") || "[]"), AppConstants.USERS_CONSTANTS.COLLECTION_POINT_MANAGER) : false,
  isCHDManager: sessionStorage.getItem("role") ? checkRequiredUser(JSON.parse(sessionStorage.getItem("role") || "[]"), AppConstants.USERS_CONSTANTS.CARREFOUR_DELIVERY_MANAGER) : false,
  isVTManager: sessionStorage.getItem("role") ? checkRequiredUser(JSON.parse(sessionStorage.getItem("role") || "[]"), AppConstants.USERS_CONSTANTS.VALET_TROLLEY_MANAGER) : false,
  isQCManager: sessionStorage.getItem("role") ? checkRequiredUser(JSON.parse(sessionStorage.getItem("role") || "[]"), AppConstants.USERS_CONSTANTS.QC_MANAGER) : false,
  isDriver: sessionStorage.getItem("role") ? checkRequiredUser(JSON.parse(sessionStorage.getItem("role") || "[]"), AppConstants.USERS_CONSTANTS.DRIVER) : false,
  isLastMileViewer: sessionStorage.getItem("currentRole") ? (sessionStorage.getItem("currentRole") || "") === AppConstants.USERS_CONSTANTS.READ_ONLY_ROLE : false,
  isDispatcher: sessionStorage.getItem("currentRole") ? (sessionStorage.getItem("currentRole") || "") === AppConstants.USERS_CONSTANTS.DISPATCHER_ROLE : false,
  isOnlyExpressDispatcher: sessionStorage.getItem("currentRole") ? (sessionStorage.getItem("currentRole") || "") === AppConstants.USERS_CONSTANTS.DISPATCHER_ROLE_EXP : false,
  isOnlyStandardDispatcher: sessionStorage.getItem("currentRole") ? (sessionStorage.getItem("currentRole") || "") === AppConstants.USERS_CONSTANTS.DISPATCHER_ROLE_STD : false,
  isOnlyStdLastMileViewer: sessionStorage.getItem("currentRole") ? (sessionStorage.getItem("currentRole") || "") === AppConstants.USERS_CONSTANTS.READ_ONLY_ROLE_STANDARD : false,
  isOnlyExpLastMileViewer: sessionStorage.getItem("currentRole") ? (sessionStorage.getItem("currentRole") || "") === AppConstants.USERS_CONSTANTS.READ_ONLY_ROLE_EXPRESS : false,
  role: "",
  showOtp: false,
};

const userLoginSlice = createSlice({
  name: "UserLogin",
  initialState,
  reducers: {},
  extraReducers: (builders) => {
    builders
      .addCase(newUserLogin.pending, (state) => {
        state.showOtp = false;
        state.errorCode = "";
        state.error = "";
        emptySessionStorage();
        state.countryCode = "";
        state.token = "";
        state.isLoggedIn = false;
        state.loading = true;
      })
      .addCase(newUserLogin.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { userdata },
          },
        } = action;
        if (payload?.jwtToken && payload?.countryCode) {
          // Skip OTP
          setSessionStorage(payload);
          const token = `Bearer ${payload.jwtToken}`;
          state.countryCode = payload.countryCode;
          state.token = token;
          state.username = userdata.username;
          state.isLoggedIn = true;
          state.role = payload?.roles?.length > 0 && payload.roles[0].code;
          let serviceType = payload.serviceTypes;
          if (payload.roles[0].code === "ROLE_SUPER_ADMIN" || payload.roles[0].code === "ROLE_COUNTRY_ADMIN" || payload.roles[0].code === "ROLE_APP_ADMIN") {
            state.isAdmin = true;
          }

          if (payload?.roles?.length > 0 && payload.roles[0].code === "ROLE_COLLECTION_POINT_MANAGER") {
            state.isCollectionPointManager = true;
          }

          if (payload?.roles?.length > 0 && payload.roles[0].code === "ROLE_LAST_MILE_DISPATCHER") {
            if (matchAllElements(["STANDARD", "EXPRESS"], serviceType)) {
              sessionStorage.setItem("currentRole", "LAST_MILE_DISPATCHER");
            }
            if (!matchAllElements(["STANDARD"], serviceType)) {
              sessionStorage.setItem("currentRole", "LAST_MILE_DISPATCHER_EXPRESS");
              state.role = AppConstants.EXPRESS_DISPATCHER_ROLE;
            }
            if (!matchAllElements(["EXPRESS"], serviceType)) {
              sessionStorage.setItem("currentRole", "LAST_MILE_DISPATCHER_STANDARD");
            }
          }

          if (payload?.roles?.length > 0 && payload.roles[0].code === "ROLE_CARREFOUR_HOME_DELIVERY_MANAGER") {
            state.isCHDManager = true;
          }
          if (payload?.roles?.length > 0 && payload.roles[0].code === "ROLE_STANDARD_VALET_TROLLEY_MANAGER") {
            state.isVTManager = true;
          }
          if (payload?.roles?.length > 0 && payload.roles[0].code === "ROLE_LAST_MILE_QC_MANAGER") {
            state.isQCManager = true;
          }
          if (payload?.roles?.length > 0 && payload.roles[0].code === "ROLE_LAST_MILE_DRIVER") {
            state.isDriver = true;
          }

          if (payload?.roles?.length > 0 && payload.roles[0].code === "ROLE_LAST_MILE_VIEWER") {
            if (matchAllElements(["STANDARD", "EXPRESS"], serviceType)) {
              sessionStorage.setItem("currentRole", "LAST_MILE_VIEWER");
            }
            if (!matchAllElements(["STANDARD"], serviceType)) {
              sessionStorage.setItem("currentRole", "LAST_MILE_VIEWER_EXPRESS");
              state.role = AppConstants.EXPRESS_VIEWER_ROLE;
            }
            if (!matchAllElements(["EXPRESS"], serviceType)) {
              sessionStorage.setItem("currentRole", "LAST_MILE_VIEWER_STANDARD");
            }
          }

          if (sessionStorage.getItem("currentRole") === "LAST_MILE_VIEWER") {
            state.isLastMileViewer = true;
          } else if (sessionStorage.getItem("currentRole") === "LAST_MILE_VIEWER_EXPRESS") {
            state.isOnlyExpLastMileViewer = true;
          } else if (sessionStorage.getItem("currentRole") === "LAST_MILE_VIEWER_STANDARD") {
            state.isOnlyStdLastMileViewer = true;
          }

          if (sessionStorage.getItem("currentRole") === "LAST_MILE_DISPATCHER") {
            state.isDispatcher = true;
          } else if (sessionStorage.getItem("currentRole") === "LAST_MILE_DISPATCHER_EXPRESS") {
            state.isOnlyExpressDispatcher = true;
          } else if (sessionStorage.getItem("currentRole") === "LAST_MILE_DISPATCHER_STANDARD") {
            state.isOnlyStandardDispatcher = true;
          }
          state.loading = false;
        } else if (payload?.error) {
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
          if (state.errorCode === AppConstants.LOGIN_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.PWD_EXP) {
            sessionStorage.setItem("username", userdata.username);
          }
          state.error = payload ? payload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
          state.isLoggedIn = false;
        } else {
          state.showOtp = true;
          sessionStorage.setItem("username", userdata?.username);
          state.loading = false;
        }
      })
      .addCase(newUserLogin.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.loading = false;
      })
      /** User Login */
      /** */
      .addCase(userLogin.pending, (state) => {
        emptySessionStorage();
        state.errorCode = "";
        state.error = "";
        state.countryCode = "";
        state.token = "";
        state.isLoggedIn = false;
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        const {
          payload,
          meta: {
            arg: { userdata },
          },
        } = action;
        state.showOtp = false;
        if (payload.error) {
          sessionStorage.setItem("token", "");
          sessionStorage.setItem("name", "");
          sessionStorage.setItem("role", "[]");
          sessionStorage.setItem("images", "");
          sessionStorage.setItem(AppConstants.COUNTRY_CODE, "");
          state.countryCode = "";
          state.token = "";
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
          if (state.errorCode === AppConstants.LOGIN_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.PWD_EXP) {
            sessionStorage.setItem("username", userdata.username);
          }
          state.error = payload ? payload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
          state.isLoggedIn = false;
        } else {
          const token = `Bearer ${payload.jwtToken}`;
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("name", payload.name);
          sessionStorage.setItem("role", JSON.stringify(payload.roles));
          sessionStorage.setItem("images", JSON.stringify(payload.images || ""));
          sessionStorage.setItem(AppConstants.COUNTRY_CODE, payload.countryCode);
          state.countryCode = payload.countryCode;
          state.errorCode = "";
          state.error = "";
          state.token = token;
          state.username = userdata.username;
          state.isLoggedIn = true;
          state.role = payload.roles[0].code;
          let serviceType = payload.serviceTypes;
          if (payload.roles[0].code === "ROLE_SUPER_ADMIN" || payload.roles[0].code === "ROLE_COUNTRY_ADMIN" || payload.roles[0].code === "ROLE_APP_ADMIN") {
            state.isAdmin = true;
          }
          if (payload.roles[0].code === "ROLE_COLLECTION_POINT_MANAGER") {
            state.isCollectionPointManager = true;
          }
          /* if (payload.roles[0].code === "ROLE_LAST_MILE_DISPATCHER") {
            state.isDispatcher = matchAllElements(["STANDARD", "EXPRESS"], serviceType);
            state.isOnlyExpressDispatcher = !matchAllElements(["STANDARD"], serviceType);
            state.isOnlyStandardDispatcher = !matchAllElements(["EXPRESS"], serviceType);
            state.role = !matchAllElements(["STANDARD"], serviceType)
              ? "ROLE_LAST_MILE_DISPATCHER_EXPRESS"
              : "ROLE_LAST_MILE_DISPATCHER";
          } */
          if (payload.roles[0].code === "ROLE_LAST_MILE_DISPATCHER") {
            if (matchAllElements(["STANDARD", "EXPRESS"], serviceType)) {
              sessionStorage.setItem("currentRole", "LAST_MILE_DISPATCHER");
            }
            if (!matchAllElements(["STANDARD"], serviceType)) {
              sessionStorage.setItem("currentRole", "LAST_MILE_DISPATCHER_EXPRESS");
              state.role = AppConstants.EXPRESS_DISPATCHER_ROLE;
            }
            if (!matchAllElements(["EXPRESS"], serviceType)) {
              sessionStorage.setItem("currentRole", "LAST_MILE_DISPATCHER_STANDARD");
            }
          }
          if (sessionStorage.getItem("currentRole") === "LAST_MILE_DISPATCHER") {
            state.isDispatcher = true;
          } else if (sessionStorage.getItem("currentRole") === "LAST_MILE_DISPATCHER_EXPRESS") {
            state.isOnlyExpressDispatcher = true;
          } else if (sessionStorage.getItem("currentRole") === "LAST_MILE_DISPATCHER_STANDARD") {
            state.isOnlyStandardDispatcher = true;
          }
          if (payload.roles[0].code === "ROLE_CARREFOUR_HOME_DELIVERY_MANAGER") {
            state.isCHDManager = true;
          }
          if (payload.roles[0].code === "ROLE_STANDARD_VALET_TROLLEY_MANAGER") {
            state.isVTManager = true;
          }
          if (payload.roles[0].code === "ROLE_LAST_MILE_QC_MANAGER") {
            state.isQCManager = true;
          }
          if (payload.roles[0].code === "ROLE_LAST_MILE_DRIVER") {
            state.isDriver = true;
          }
          if (payload.roles[0].code === "ROLE_LAST_MILE_VIEWER") {
            if (matchAllElements(["STANDARD", "EXPRESS"], serviceType)) {
              sessionStorage.setItem("currentRole", "LAST_MILE_VIEWER");
            }
            if (!matchAllElements(["STANDARD"], serviceType)) {
              sessionStorage.setItem("currentRole", "LAST_MILE_VIEWER_EXPRESS");
              state.role = AppConstants.EXPRESS_VIEWER_ROLE;
            }
            if (!matchAllElements(["EXPRESS"], serviceType)) {
              sessionStorage.setItem("currentRole", "LAST_MILE_VIEWER_STANDARD");
            }
          }
          if (sessionStorage.getItem("currentRole") === "LAST_MILE_VIEWER") {
            state.isLastMileViewer = true;
          } else if (sessionStorage.getItem("currentRole") === "LAST_MILE_VIEWER_EXPRESS") {
            state.isOnlyExpLastMileViewer = true;
          } else if (sessionStorage.getItem("currentRole") === "LAST_MILE_VIEWER_STANDARD") {
            state.isOnlyStdLastMileViewer = true;
          }
          state.loading = false;
        }
      })
      .addCase(userLogin.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        state.loading = false;
      })
      .addCase(userResetPwd.pending, (state) => {
        state.loading = true;
        state.errorCode = "";
        state.error = "";
      })
      .addCase(userResetPwd.fulfilled, (state, action) => {
        const payload: any = action.payload;
        state.loading = false;
        if (payload.error) {
          state.resetPwdSuccess = false;
          state.errorCode = payload.error || AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
          state.error = payload ? payload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
        } else {
          sessionStorage.setItem("token", "");
          sessionStorage.setItem("username", "");
          sessionStorage.setItem("name", "");
          sessionStorage.setItem("role", "[]");
          sessionStorage.setItem("images", "");
          sessionStorage.setItem(AppConstants.COUNTRY_CODE, "");
          state.countryCode = "";
          state.errorCode = "";
          state.error = "";
          state.resetPwdSuccess = true;
          state.resetPwdMsg = payload;
        }
      })
      .addCase(userResetPwd.rejected, (state, action) => {
        const errorPayload: any = action.payload;
        state.loading = false;
        state.resetPwdSuccess = false;
        state.errorCode = errorPayload ? errorPayload.error : AppConstants.RESPONSE_CONSTANTS.ERROR_CODES.DEFAULT_API_FAILED;
        state.error = errorPayload ? errorPayload.message : AppConstants.RESPONSE_CONSTANTS.DEFAULT_API_FAILED_ERROR_MSG;
      })
      .addCase(resetUserResetPwd.fulfilled, (state, action) => {
        const { payload } = action;
        state.resetPwdSuccess = payload;
      })
      .addCase(resetUserError.fulfilled, (state, action) => {
        const { payload } = action;
        state.errorCode = payload;
        state.error = payload;
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        const { payload } = action;
        sessionStorage.setItem("token", "");
        sessionStorage.setItem("username", "");
        sessionStorage.setItem("name", "");
        sessionStorage.setItem("role", "[]");
        sessionStorage.setItem("images", "");
        sessionStorage.setItem(AppConstants.HUB_CODE, "[]");
        sessionStorage.setItem(AppConstants.COUNTRY_CODE, "");
        sessionStorage.setItem("currentRole", "");
        state.countryCode = "";
        state.loading = false;
        state.errorCode = "";
        state.error = "";
        state.token = "";
        state.username = "";
        state.isAdmin = false;
        state.isCHDManager = false;
        state.isVTManager = false;
        state.isCollectionPointManager = false;
        state.isDriver = false;
        state.isLoggedIn = payload;
        state.isOnlyExpressDispatcher = false;
        state.isOnlyStandardDispatcher = false;
        state.isDispatcher = false;
        state.isLastMileViewer = false;
        state.isOnlyStdLastMileViewer = false;
        state.isOnlyExpLastMileViewer = false;
      });
  },
});

export default userLoginSlice.reducer;
